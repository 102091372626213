import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';

import { fadeOut } from '@/utils/main/AnimateImage';
import IntroStyle from '@/components/main/sections/Intro/Intro.module.scss';
import introData from '@/components/main/sections/Intro/Intro_new.json';

const setDidPlayIntro = () => {
  window.sessionStorage.setItem('did-play-intro', 'true');
};

const getDidPlayIntro = () => {
  try {
    const didPlayIntro = window.sessionStorage.getItem('did-play-intro');
    return Boolean(didPlayIntro);
  } catch (error) {}
};

const Intro = (props) => {
  const { onVideoEnd, onIntroEnd } = props;
  const introContainerRef = useRef(null);
  const introPlayerRef = useRef(null);
  const lottieInstance = useRef();

  useEffect(() => {
    if (!introPlayerRef.current) return;

    const didPlayIntro = getDidPlayIntro();

    if (didPlayIntro) {
      onVideoEnd();
      return onIntroEnd();
    }

    window.scrollTo({ top: 0 });

    document.body.style.overflow = 'hidden';
    introPlayerRef.current.style.display = 'block';

    lottieInstance.current = lottie.loadAnimation({
      loop: false,
      autoplay: true,
      renderer: 'svg',
      rendererSettings: {
        progressiveLoad: true,
      },
      animationData: introData,
      container: introPlayerRef.current,
    });

    setDidPlayIntro();
    lottieInstance.current.addEventListener('complete', async () => {
      onVideoEnd();
      await fadeOut(introContainerRef.current);
      onIntroEnd();
      document.body.style.overflow = '';
    });

    return () => {
      document.body.style.overflow = '';
      lottieInstance.current.destroy();
    };
  }, [onVideoEnd, onIntroEnd]);

  return (
    <section ref={introContainerRef} className={IntroStyle.introContainer}>
      <div ref={introPlayerRef} className={IntroStyle.introPlayer} />
    </section>
  );
};

Intro.propTypes = {
  onVideoEnd: PropTypes.func,
  onIntroEnd: PropTypes.func,
};

Intro.defaultProps = {
  onVideoEnd: () => {},
  onIntroEnd: () => {},
};

export default Intro;
